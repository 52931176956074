<template>
  <div class="custom-actions">
    <b-button
      class="m-1"
      type="button"
      variant="success"
      title="Edit User"
      @click="itemAction('edit-item', rowData, rowIndex)">
      <b-icon icon="pencil" aria-hidden="true"></b-icon>
    </b-button>
    <b-button
      class="m-1"
      variant="danger"
      title="Delete User"
      @click="itemAction('delete-item', rowData, rowIndex)">
      <b-icon icon="trash" aria-hidden="true"></b-icon>
    </b-button>
  </div>
</template>

<script>
  const EditUser = () => import('./EditUser.vue');
  
  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    methods : {

      /**
       * Delete/Edit Item Action
       * @param action
       * @param data
       * @param index
       */
      // eslint-disable-next-line no-unused-vars
      itemAction(action, data, index) {
        if (action === 'delete-item') {
          this.$modal.show('dialog', {
            title : 'Please confirm:',
            text  : ' Are you sure you want to delete ' + data.firstname + ' ' +
              data.lastname + '?',
            buttons : [ {
              title   : 'Yes', handler : () => {
                this.$http.delete('/api/user', {
                  data : {
                    'userId' : data.user_id,
                  },
                }).then(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success',
                    text  : 'Deleted user ' + data.firstname + ' ' +
                      data.lastname + ' successfully!',
                  });

                  this.$parent.reload();
                  this.$modal.hide('dialog');
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                });
              },
            }, {
              title : 'No',
            } ],

          });

        } else if (action === 'edit-item') {
          this.$modal.show(
            EditUser,
            { data : data },
            { height : 'auto', adaptive : true },
            { 'update-user-list' : (event) => {
              this.$parent.reload();
            }}
          );
        }
      },
    },
    components : {
      // eslint-disable-next-line vue/no-unused-components
      EditUser,
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/userlist/user-list-actions";
</style>
